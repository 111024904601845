// src/lib/isc/utils/userOpUtils.ts
import { keccak256, encodeAbiParameters, Address, Hex, toHex, concat } from 'viem';
import { PackedUserOperation, RpcUserOperation } from '../types/userOps.type';

export function getUserOperationHash(userOp: PackedUserOperation, entryPoint: Address, chainId: bigint): Hex {
  const hashedInitCode = keccak256(userOp.initCode);
  const hashedCallData = keccak256(userOp.callData);
  const hashedPaymasterAndData = keccak256(userOp.paymasterAndData);

  const packedUserOp = encodeAbiParameters(
    [
      { type: 'address' },
      { type: 'uint256' },
      { type: 'bytes32' },
      { type: 'bytes32' },
      { type: 'bytes32' }, // accountGasLimits
      { type: 'uint256' },
      { type: 'bytes32' }, // gasFees
      { type: 'bytes32' },
    ],
    [
      userOp.sender,
      userOp.nonce,
      hashedInitCode,
      hashedCallData,
      userOp.accountGasLimits,
      userOp.preVerificationGas,
      userOp.gasFees,
      hashedPaymasterAndData
    ]
  );

  const hash = keccak256(packedUserOp);

  return keccak256(
    encodeAbiParameters(
      [
        { type: 'bytes32' },
        { type: 'address' },
        { type: 'uint256' }
      ],
      [hash, entryPoint, chainId]
    )
  );
}

function ensureHex(value: string | Hex): Hex {
  if (typeof value === 'string') {
    return (value.startsWith('0x') ? value : `0x${value}`) as Hex;
  }
  return value;
}

export function rpcToPackedUserOp(rpcUserOp: RpcUserOperation): PackedUserOperation {
  const initCode = concat([rpcUserOp.factory, rpcUserOp.factoryData]);
  const accountGasLimits = concat([rpcUserOp.verificationGasLimit, rpcUserOp.callGasLimit]);
  const gasFees = concat([rpcUserOp.maxPriorityFeePerGas, rpcUserOp.maxFeePerGas]);
  const paymasterAndData = concat([
    rpcUserOp.paymaster,
    rpcUserOp.paymasterVerificationGasLimit,
    rpcUserOp.paymasterPostOpGasLimit,
    rpcUserOp.paymasterData
  ]);

  return {
    sender: rpcUserOp.sender,
    nonce: BigInt(rpcUserOp.nonce),
    initCode,
    callData: rpcUserOp.callData,
    accountGasLimits,
    preVerificationGas: BigInt(rpcUserOp.preVerificationGas),
    gasFees,
    paymasterAndData,
    signature: rpcUserOp.signature,
  };
}

export function packedToRpcUserOp(packedUserOp: PackedUserOperation): RpcUserOperation {
  const factory = packedUserOp.initCode.slice(0, 42) as Address;
  const factoryData = ensureHex(packedUserOp.initCode.slice(42));
  const accountGasLimits = packedUserOp.accountGasLimits.slice(2); // Remove '0x' prefix
  const verificationGasLimit = ensureHex(accountGasLimits.slice(0, 32));
  const callGasLimit = ensureHex(accountGasLimits.slice(32));
  const gasFees = packedUserOp.gasFees.slice(2); // Remove '0x' prefix
  const maxPriorityFeePerGas = ensureHex(gasFees.slice(0, 32));
  const maxFeePerGas = ensureHex(gasFees.slice(32));
  const paymaster = packedUserOp.paymasterAndData.slice(0, 42) as Address;
  const paymasterAndData = packedUserOp.paymasterAndData.slice(42);
  const paymasterVerificationGasLimit = ensureHex(paymasterAndData.slice(0, 32));
  const paymasterPostOpGasLimit = ensureHex(paymasterAndData.slice(32, 64));
  const paymasterData = ensureHex(paymasterAndData.slice(64));

  return {
    sender: packedUserOp.sender,
    nonce: toHex(packedUserOp.nonce),
    factory,
    factoryData,
    callData: packedUserOp.callData,
    callGasLimit: callGasLimit === '0x' ? '0x5208' : callGasLimit,
    verificationGasLimit: verificationGasLimit === '0x' ? '0x5208' : verificationGasLimit,
    preVerificationGas: toHex(packedUserOp.preVerificationGas),
    maxFeePerGas: maxFeePerGas === '0x' ? '0x3b9aca00' : maxFeePerGas,
    maxPriorityFeePerGas: maxPriorityFeePerGas === '0x' ? '0x3b9aca00' : maxPriorityFeePerGas,
    paymaster,
    paymasterVerificationGasLimit: paymasterVerificationGasLimit === '0x' ? '0x5208' : paymasterVerificationGasLimit,
    paymasterPostOpGasLimit: paymasterPostOpGasLimit === '0x' ? '0x5208' : paymasterPostOpGasLimit,
    paymasterData,
    signature: packedUserOp.signature,
  };
}

// export function packedToRpcUserOp(packedUserOp: PackedUserOperation): RpcUserOperation {
//   const factory = packedUserOp.initCode.slice(0, 42) as Address;
//   const factoryData = ensureHex(packedUserOp.initCode.slice(42));
//   const verificationGasLimit = ensureHex(packedUserOp.accountGasLimits.slice(0, 34));
//   const callGasLimit = ensureHex(packedUserOp.accountGasLimits.slice(34));
//   const maxPriorityFeePerGas = ensureHex(packedUserOp.gasFees.slice(0, 34));
//   const maxFeePerGas = ensureHex(packedUserOp.gasFees.slice(34));
//   const paymaster = packedUserOp.paymasterAndData.slice(0, 42) as Address;
//   const paymasterVerificationGasLimit = ensureHex(packedUserOp.paymasterAndData.slice(42, 76));
//   const paymasterPostOpGasLimit = ensureHex(packedUserOp.paymasterAndData.slice(76, 110));
//   const paymasterData = ensureHex(packedUserOp.paymasterAndData.slice(110));

//   return {
//     sender: packedUserOp.sender,
//     nonce: toHex(packedUserOp.nonce),
//     factory,
//     factoryData,
//     callData: packedUserOp.callData,
//     callGasLimit,
//     verificationGasLimit,
//     preVerificationGas: toHex(packedUserOp.preVerificationGas),
//     maxFeePerGas,
//     maxPriorityFeePerGas,
//     paymaster,
//     paymasterVerificationGasLimit,
//     paymasterPostOpGasLimit,
//     paymasterData,
//     signature: packedUserOp.signature,
//   };
// }
// export function packedToRpcUserOp(packedUserOp: PackedUserOperation): RpcUserOperation {
//   console.log('accountGasLimits before slice operation:', packedUserOp.accountGasLimits);
//   const accountGasLimitsHex = packedUserOp.accountGasLimits.slice(2);
//   console.log('accountGasLimitsHex after slice operation:', accountGasLimitsHex);
  
//   // if (!accountGasLimitsHex || !gasFeesHex) {
//   //   console.error('accountGasLimits or gasFees are undefined');
//   //   throw new Error('accountGasLimits or gasFees are undefined');
//   // }
  
//   console.log('gasFees before slice operation:', packedUserOp.gasFees);
//   const gasFeesHex = packedUserOp.gasFees.slice(2);
//   console.log('gasFeesHex after slice operation:', gasFeesHex);
  
//   console.log('verificationGasLimit before slice operation:', accountGasLimitsHex);
//   const verificationGasLimit = ensureHex(accountGasLimitsHex.slice(0, 32));
//   console.log('verificationGasLimit after slice operation:', verificationGasLimit);
  
//   console.log('callGasLimit before slice operation:', accountGasLimitsHex);
//   const callGasLimit = ensureHex(accountGasLimitsHex.slice(32));
//   console.log('callGasLimit after slice operation:', callGasLimit);
  
//   console.log('maxFeePerGas before slice operation:', gasFeesHex);
//   const maxFeePerGas = ensureHex(gasFeesHex.slice(0, 32));
//   console.log('maxFeePerGas after slice operation:', maxFeePerGas);
  
//   console.log('maxPriorityFeePerGas before slice operation:', gasFeesHex);
//   const maxPriorityFeePerGas = ensureHex(gasFeesHex.slice(32));
//   console.log('maxPriorityFeePerGas after slice operation:', maxPriorityFeePerGas);
  
//   return {
//     sender: packedUserOp.sender,
//     nonce: toHex(packedUserOp.nonce),
//     initCode: packedUserOp.initCode,
//     callData: packedUserOp.callData,
//     callGasLimit,
//     verificationGasLimit,
//     preVerificationGas: toHex(packedUserOp.preVerificationGas),
//     maxFeePerGas,
//     maxPriorityFeePerGas,
//     paymasterAndData: packedUserOp.paymasterAndData,
//     signature: packedUserOp.signature,
//   };
// }
// export function packedToRpcUserOp(packedUserOp: PackedUserOperation): RpcUserOperation {
//   const callGasLimit = packedUserOp.accountGasLimits.slice(34);
//   const verificationGasLimit = packedUserOp.accountGasLimits.slice(2, 34);
//   const maxFeePerGas = packedUserOp.gasFees.slice(2, 34);
//   const maxPriorityFeePerGas = packedUserOp.gasFees.slice(34);

//   return {
//     sender: packedUserOp.sender,
//     nonce: toHex(packedUserOp.nonce),
//     initCode: packedUserOp.initCode,
//     callData: packedUserOp.callData,
//     callGasLimit: `0x${callGasLimit}`,
//     verificationGasLimit: `0x${verificationGasLimit}`,
//     preVerificationGas: toHex(packedUserOp.preVerificationGas),
//     maxFeePerGas: `0x${maxFeePerGas}`,
//     maxPriorityFeePerGas: `0x${maxPriorityFeePerGas}`,
//     paymasterAndData: packedUserOp.paymasterAndData,
//     signature: packedUserOp.signature,
//   };
// }